<template>
  <v-autocomplete
    :items="clients"
    :label="
      $helpers.string.capitalize($tc('client.client', 1), {
        required: $attrs['required'] != null,
      })
    "
    v-bind="$attrs"
    v-on="$listeners"
    item-value="id"
    :item-text="getFullName"
    clearable
    prepend-icon="mdi-account-multiple"
    :rules="$attrs['required'] != null ? $helpers.rule.notEmpty() : []"
  ></v-autocomplete>
  <!--   
  <v-layout>
    <v-autocomplete
      :items="clients"
      v-bind="$attrs"
      v-on="$listeners"
      item-value="id"
      :item-text="getFullName"
      :label="$helpers.string.capitalizeI18N('client')"
      ref="clientsSelect"
      @keydown.enter="enterPressed($event)"
      :readonly="$attrs['value'] != null"
      :search-input.sync="clientName"
    >
      <template v-slot:append-outer>
        <v-btn icon @click="clearClicked" :disabled="isEditDisabled()" tabindex="-1">
          <v-icon>close</v-icon>
        </v-btn>
        <v-btn icon @click="editClicked" :disabled="isEditDisabled()" tabindex="-1">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon @click="addClicked" tabindex="-1">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <i18n path="no-matching-create" tag="p">
                <template v-slot:search>
                  <span class="font-weight-bold">{{ clientName }}</span>
                </template>
                <template v-slot:enterKey>
                  <kbd>enter</kbd>
                </template>
              </i18n>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <ClientEditionPopup ref="clientEdition" @onSaveClicked="saveClicked" />
  </v-layout> -->
</template>

<script>
//import ClientEditionPopup from "./ClientEditionPopup.vue";

export default {
  name: "SelectableClient",
  data() {
    return {
      clientName: "",
      clients: [],
    };
  },
  components: {
    //ClientEditionPopup,
  },
  mounted() {
    console.dir(this.$attrs);
    this.retrieveClients();
  },
  methods: {
    getFullName(client) {
      const fullName = (
        (client.firstName ?? "") +
        " " +
        (client.lastName ?? "")
      ).trim();

      const name = client.companyName
        ? fullName
          ? `${fullName} (${client.companyName})`
          : client.companyName
        : fullName;

      return client.active ? name : `${name} (${this.$t("inactive")})`;
    },
    isEditDisabled() {
      return this.$attrs["value"] == null;
    },
    enterPressed() {
      if (
        this.$refs.clientsSelect.computedItems.length == 0 &&
        this.clientName != null
      ) {
        this.saveClicked({ firstName: this.clientName }).then(() => {
          this.$refs.clientsSelect.blur();
        });
      }
    },
    clearClicked() {
      this.$attrs["value"] = null;
      this.$emit("input", this.$attrs["value"]);
      this.$refs.clientsSelect.focus();
    },
    addClicked() {
      this.$refs.clientEdition.open({});
    },
    editClicked() {
      this.$services.client
        .get(this.$refs.clientsSelect.value)
        .then((response) => {
          this.$refs.clientEdition.open(response.data);
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N("clients.error.notfound");
        });
    },
    retrieveClients() {
      return this.$services.client
        .getAll()
        .then((response) => {
          this.clients = response.data;
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N("clients.error.retrieve");
        });
    },
    saveClicked(value) {
      this.retrieveClients().then(() => {
        this.$attrs["value"] = value.id;
        this.$emit("input", this.$attrs["value"]);
        this.$emit("change", this.$attrs["value"]);
      });
    },
  },
};
</script>