<template>
  <v-row justify="center">
    <v-dialog ref="dialog" v-model="dialog" :return-value.sync="planning" persistent max-width="800px"
      @keydown.esc="close()" :fullscreen="$helpers.display.mobileDialog" :hide-overlay="$helpers.display.mobileDialog"
      transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <span class="headline">
            <v-icon v-if="planning.canceled" class="mr-2" x-large>mdi-cancel</v-icon>
            {{
              $helpers.string.capitalizeI18N(
                planning.id == null ? "planning.creation" : planning.canceled ? "planning.canceled" : "planning.edition"
              )
            }}
          </span>
          <v-spacer />
          <PopupCloseButton @closeClick="close" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form" :readonly="planning.canceled">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row no-gutters>
                  <v-col>
                    <SelectableLesson v-model="planning.lessonId" ref="selectableLesson" v-if="!planning.name"
                      @input="setLesson" required :disabled="planning.canceled" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <DatePicker v-model="dateBegin" :label="$helpers.string.capitalizeI18N('date-begin', {
                      required: true,
                    })
                      " required :disabled="planning.canceled" />
                    <DatePicker v-model="dateEnd" :label="$helpers.string.capitalizeI18N('date-end', {
                      required: true,
                    })
                      " required :disabled="planning.canceled" />
                  </v-col>
                  <v-col class="ml-2">
                    <TimePicker v-model="timeBegin" :label="$helpers.string.capitalizeI18N('hour-begin', {
                      required: true,
                    })
                      " required :disabled="planning.canceled" />
                    <TimePicker v-model="timeEnd" :label="$helpers.string.capitalizeI18N('hour-end', {
                      required: true,
                    })
                      " required :disabled="planning.canceled" />
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col>
                    <SelectableUser v-model="planning.userId" ref="selectableUser" :disabled="planning.canceled" />
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
            <FormGroupElement :expanded="showMemberships" v-show="canEditMembership()">
              <template v-slot:title>
                <span class="title">{{ $helpers.string.capitalizeI18N("participants") }}
                </span>
                <PlanningUnitsChip :usedUnits="getUsedUnits()" :totalUnits="getMaximumUnit()" small />
              </template>

              <MembershipSelection ref="membershipSelection" v-model="planning" :lesson="lesson" />
            </FormGroupElement>
          </v-form>
          <v-container>
            <v-row>
              <v-col>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" block text color="error" v-show="planning.id"
                      :loading="deleteLoading">
                      <v-icon left>mdi-delete</v-icon>
                      supprimer
                      <v-icon right>mdi-chevron-down</v-icon></v-btn>
                  </template>
                  <v-list>
                    <v-list-item link @click="remove({ next: false })">
                      <v-list-item-title>Cette séance uniquement</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="remove({ next: true })">
                      <v-list-item-title>Cette séance et les suivantes</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" block text color="info" v-show="planning.id"
                      :disabled="planning.canceled" :loading="cancelLoading">
                      <v-icon left>mdi-cancel</v-icon>
                      annuler
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link @click="cancel({ next: false })">
                      <v-list-item-title>Cette séance uniquement</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="cancel({ next: true })">
                      <v-list-item-title>Cette séance et les suivantes</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <CardActionButton @click="close" type="close" /> -->
          <CardActionButton @click="saveAndClose" type="save" v-show="!planning.canceled" ref="saveButton" />
          <!-- <CardActionButton @click="saveAndNew" i18ntext="saveandnew" /> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CheckboxConfirmDialog ref="checkboxConfirm"></CheckboxConfirmDialog>
  </v-row>
</template>


<script>
import CardActionButton from "@/components/Admin/Card/CardActionButton";
import { mask } from "vue-the-mask";
// import ColorPicker from "@/components/Admin/ColorPicker";
import DatePicker from "@/components/Admin/DatePicker";
import TimePicker from "@/components/Admin/TimePicker";
import SelectableLesson from "@/components/Admin/Lesson/SelectableLesson";
import SelectableUser from "@/components/Admin/User/SelectableUser";
import MembershipSelection from "./MembershipSelection";
import FormGroupElement from "@/components/Admin/FormGroupElement";
import PlanningUnitsChip from "@/components/Admin/Unit/PlanningUnitsChip";
import PopupCloseButton from "@/components/Admin/PopupCloseButton";
import CheckboxConfirmDialog from "@/components/Admin/CheckboxConfirmDialog";

export default {
  name: "PlanningEditionPopup",
  data() {
    return {
      dialog: false,
      planning: {},
      dateBegin: null,
      dateEnd: null,
      timeBegin: null,
      timeEnd: null,
      showMoreDate: false,
      lesson: null,
      cancelLoading: false,
      deleteLoading: false,
    };
  },
  directives: {
    mask,
  },
  components: {
    CardActionButton,
    // ColorPicker,
    DatePicker,
    SelectableLesson,
    TimePicker,
    MembershipSelection,
    SelectableUser,
    FormGroupElement,
    PlanningUnitsChip,
    PopupCloseButton,
    CheckboxConfirmDialog,
  },
  watch: {
    dateBegin: function (val, prev) {
      if (prev == this.dateEnd) {
        this.dateEnd = val;
      }
    },
  },
  computed: {
    showMemberships() {
      return this.planning?.planning_memberships?.length > 0;
    },
  },
  methods: {
    getMaximumUnit() {
      return Number(this.lesson?.maximumUnit);
    },
    getUsedUnits() {
      return (
        this.planning?.planning_memberships?.reduce(function (a, b) {
          return Number(a) + Number(b.unit ?? 0);
        }, 0) ?? 0
      );
    },
    showMoreDateOption() {
      this.showMoreDate = !this.showMoreDate;
    },
    canEditMembership() {
      if (!this.planning) return true;

      if (!this.planning.lessonId) return false;

      return !this.planning.canceled;
    },
    async deletePlanning({ next = false }) {
      try {
        await this.$services.planning.delete(this.planning, { next: next });

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async savePlanning() {
      try {
        const begin = this.$helpers.date.addTimeStringToDate(
          this.dateBegin,
          this.timeBegin
        );
        const end = this.$helpers.date.addTimeStringToDate(
          this.dateEnd ?? this.dateBegin,
          this.timeEnd
        );

        if (end < begin) throw new Error(this.$t("invalid-dates-times"));

        this.planning.dateBegin = begin;
        this.planning.dateEnd = end;

        // if (
        //   this.planning.color != null &&
        //   (typeof this.planning.color !== "string" ||
        //     !(this.planning.color instanceof String))
        // ) {
        //   this.planning.color = this.planning.color.hexa;
        // }

        await this.$services.planning.save(this.planning);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async loadPlanning() {
      this.dateBegin = this.planning.dateBegin
        ? new Date(this.planning.dateBegin).toISOString().substring(0, 10)
        : new Date().toISOString().substring(0, 10);

      this.dateEnd = this.planning.dateEnd
        ? new Date(this.planning.dateEnd).toISOString().substring(0, 10)
        : new Date().toISOString().substring(0, 10);

      // if (dateEnd !== this.dateBegin) {
      //   this.dateEnd = dateEnd;
      //   this.showMoreDate = true;
      // }

      this.timeBegin = this.$helpers.date.getTimeStringFromDate(
        this.planning.dateBegin ?? new Date()
      );
      this.timeEnd = this.$helpers.date.getTimeStringFromDate(
        this.planning.dateEnd ?? this.$moment().add({ hours: 1 }).toDate()
      );

      // if (this.$refs.colorPicker)
      //   this.$refs.colorPicker.color = this.planning.color;

      await this.setLesson(this.planning.lessonId);

      // if (!this.planning.planning_memberships)
      // this.planning.planning_memberships = [];
      //this.color = this.planning.color;

      //console.log(this.timeBegin);
    },
    async cancel({ next = false }) {
      var confirm = await this.$refs.checkboxConfirm.open(
        this.$helpers.string.capitalizeI18N("confirmation"),
        this.$t(
          next ? "planning.cancel-next.message" : "planning.cancel.message"
        ),
        this.$t("planning.cancel.alert-clients-email"),
        true
      );

      if (confirm) {
        try {
          this.cancelLoading = true;

          await this.$services.planning.cancel(this.planning.id, {
            sendEmailPlanningCancellation: this.$refs.checkboxConfirm.checkbox,
            next: next,
          });

          this.$helpers.snackbar.showSuccessI18N(next ? "planning.cancel-next.success" : "planning.cancel.success");

          this.$emit("onSaveClicked", this.planning);
          this.dialog = false;
        } catch (error) {
          this.$helpers.snackbar.handleError(error);
        } finally {
          this.cancelLoading = false;
        }
      }
    },
    async remove({ next = false }) {
      var confirm = await this.$root.$confirm(
        this.$helpers.string.capitalizeI18N("confirmation"),
        this.$t(
          next ? "planning.delete-next.message" : "planning.delete.message"
        )
      );

      if (confirm) {
        try {
          this.deleteLoading = true;

          await this.deletePlanning({ next: next });

          this.$helpers.snackbar.showSuccessI18N(
            next ? "planning.delete-next.success" : "planning.delete.success"
          );

          this.$emit("onSaveClicked", this.planning);
          this.dialog = false;
        } catch (error) {
          this.$helpers.snackbar.handleError(error);
        } finally {
          this.deleteLoading = false;
        }
      }
    },
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        if (!this.$refs.form.validate()) return;

        await this.savePlanning();

        this.$helpers.snackbar.showSuccessI18N("planning.save.success");

        this.$emit("onSaveClicked", this.planning);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    async saveAndNew() {
      this.savePlanning();
      this.$emit("onSaveClicked", this.planning);
      this.planning = {};
      await this.loadPlanning();
      this.$refs.name.focus();
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    async open(planning) {
      //console.dir(planning);
      if (!planning.planning_memberships) {
        planning.planning_memberships = new Array();
      }

      this.planning = planning;
      await this.loadPlanning();
      this.dialog = true;
    },
    async setLesson(lessonId) {
      if (!lessonId) {
        this.lesson = null;
        return;
      }

      const lesson = await this.$services.lesson.get(lessonId);

      this.lesson = lesson.data;

      if (!this.planning.id && this.lesson && this.lesson.userId) {
        this.planning.userId = this.lesson.userId;
      }
    },
  },
};
</script>
