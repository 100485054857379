import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c('AvailableMembershipSelection',{directives:[{name:"show",rawName:"v-show",value:(_vm.canEditMembership),expression:"canEditMembership"}],attrs:{"planning":_vm.$attrs.value},on:{"onAddMembershipClicked":_vm.addMembership}})],1),_c(VRow,[_c(VCol,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Client ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Abonnement ")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"60px !important"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-information ")]),_vm._v(" Nb. unité ")],1)]}}])},[_c('span',[_vm._v("Ajustez le nombre d'unité pour décrémenter ou non l'abonnement")])])],1),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.planningMemberships),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(_vm.getFullClientName(item.membership.client))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getMembershipTypeName(item.membership.membershipTypeId))+" ")]),_c('td',{staticClass:"d-flex",staticStyle:{"background-color":"transparent"}},[_c(VSelect,{staticClass:"d-flex align-center align-self-start mt-1",attrs:{"items":[0, 1],"dense":"","outlined":"","disabled":!_vm.canEditMembership},model:{value:(item.unit),callback:function ($$v) {_vm.$set(item, "unit", _vm._n($$v))},expression:"item.unit"}})],1),_c('td',[_c(VBtn,{staticClass:"ml-2",attrs:{"x-small":"","color":"primary","fab":"","disabled":!_vm.canEditMembership},on:{"click":function($event){return _vm.removePlanningMembership(item)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)])}),0)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }