<template>
  <v-chip :color="getColor()" text-color="white" v-bind="$attrs">
    <v-icon small class="mr-1">mdi-account-multiple</v-icon><span v-if="totalUnitsValue != '~'">{{ usedUnits }}/{{
      totalUnitsValue }}</span><span v-else>{{ usedUnits }}</span></v-chip>
</template>

<script>
export default {
  name: "PlanningUnitsChip",
  props: {
    usedUnits: Number,
    totalUnits: Number,
  },
  computed: {
    totalUnitsValue: function () {
      return this.totalUnits == 0 ? "~" : this.totalUnits;
    },
  },
  methods: {
    getColor() {
      if (this.totalUnitsValue == "~") return "orange";

      if (this.usedUnits == this.totalUnits) return "green";

      if (this.usedUnits > 0) return "orange";

      if (this.usedUnits == 0) return "red";

      return "orange";
    },
  },
};
</script>