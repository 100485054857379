<template>
  <v-row justify="center">
    <v-dialog
      ref="dialog"
      v-model="dialog"
      :return-value.sync="membership"
      persistent
      max-width="800px"
      @keydown.esc="close()"
      :fullscreen="$helpers.display.mobileDialog"
      :hide-overlay="$helpers.display.mobileDialog"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $helpers.string.capitalizeI18N(
              membership.id == null
                ? "membership.creation"
                : "membership.edition"
            )
          }}</span>
          <v-spacer />
          <v-btn icon fab @click="close"
            ><v-icon large>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row>
                  <v-col>
                    <SelectableClient
                      v-model="membership.clientId"
                      ref="selectableClient"
                      required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <SelectableMembershipType
                      v-model="membership.membershipTypeId"
                      @input="membershipTypeChanged"
                      ref="selectableMembershipType"
                      required
                    />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col>
                    <DatePicker
                      v-model="dateBegin"
                      v-bind:label="
                        $helpers.string.capitalizeI18N('date-begin')
                      "
                    />
                  </v-col>
                  <v-col
                    v-show="
                      membership.membershipTypeId &&
                      getValidityUnit() &&
                      getValidityValue()
                    "
                    class="text-center"
                  >
                    <v-btn
                      text
                      @click="computeEndDate()"
                      color="primary"
                      outlined
                    >
                      + {{ getValidityValue() }}
                      {{ getValidityUnit() }}
                    </v-btn>
                  </v-col>

                  <v-col>
                    <DatePicker
                      v-model="dateEnd"
                      v-bind:label="$helpers.string.capitalizeI18N('date-end')"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      :label="
                        $helpers.string.capitalizeI18N('price-vat-included')
                      "
                      v-model="membership.priceVatIncluded"
                      type="number"
                      :placeholder="$helpers.string.capitalizeI18N('free')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :label="
                        $helpers.string.capitalizeI18N('vat-rate', {
                          required: true,
                        })
                      "
                      v-model="membership.vatRate"
                      type="number"
                      suffix="%"
                      required
                      :rules="$helpers.rule.notEmpty()"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>

            <FormGroupElement
              :showMoreButton="false"
              :title="$helpers.string.capitalizeI18N('payment')"
              :color="statusColor"
              :expanded="true"
            >
              <v-container>
                <v-row align="center" justify="space-around">
                  <v-col>
                    <v-btn
                      text
                      outlined
                      x-large
                      block
                      @click="paymentStatus = 0"
                      :color="paymentStatus == 0 ? 'red' : ''"
                    >
                      <span>{{
                        $helpers.string.capitalizeI18N(
                          "payment-status.not-paid"
                        )
                      }}</span>
                      <v-icon class="hidden-sm-and-down" right small
                        >mdi-cancel</v-icon
                      >
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      text
                      outlined
                      x-large
                      block
                      @click="paymentStatus = 1"
                      :color="paymentStatus == 1 ? 'green' : ''"
                    >
                      <span>{{
                        $helpers.string.capitalizeI18N("payment-status.paid")
                      }}</span>
                      <v-icon class="hidden-sm-and-down" right small
                        >mdi-thumb-up-outline</v-icon
                      >
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      text
                      outlined
                      x-large
                      block
                      @click="paymentStatus = 2"
                      :color="paymentStatus == 2 ? 'blue' : ''"
                    >
                      <span>{{
                        $helpers.string.capitalizeI18N("payment-status.pending")
                      }}</span>
                      <v-icon class="hidden-sm-and-down" right small
                        >mdi-timer-sand-empty</v-icon
                      >
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="shrink">
                    <DatePicker
                      v-model="paymentDate"
                      :clearable="false"
                      required
                    />
                  </v-col>
                  <v-col>
                    <SelectablePaymentType v-model="paymentTypeId" />
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="membership.archived"
                  label="Archiver et ne plus afficher sur mon dashboard"
                  v-if="membership.id"
                />
              </v-col>
            </v-row>
          </v-form>
          <small>* {{ $t("required-fields") }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CardActionButton
            @click="saveAndClose"
            type="save"
            ref="saveButton"
          />
          <!-- <CardActionButton @click="saveAndNew" i18ntext="saveandnew" /> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import CardActionButton from "@/components/Admin/Card/CardActionButton";
import SelectableClient from "../Client/SelectableClient";
import SelectablePaymentType from "../PaymentType/SelectablePaymentType";
import DatePicker from "@/components/Admin/DatePicker";
import SelectableMembershipType from "../MembershipType/SelectableMembershipType";
import FormGroupElement from "@/components/Admin/FormGroupElement";

import { addMonths, addYears, addDays, addWeeks } from "date-fns";

export default {
  name: "MembershipEditionPopup",
  data: () => ({
    dateBegin: new Date().toISOString().substr(0, 10),
    dateEnd: new Date().toISOString().substr(0, 10),
    paymentDate: new Date().toISOString().substr(0, 10),
    dialog: false,
    membership: {},
  }),
  components: {
    CardActionButton,
    SelectableClient,
    DatePicker,
    SelectablePaymentType,
    SelectableMembershipType,
    FormGroupElement,
  },
  computed: {
    statusColor: function () {
      switch (this.membership.payment?.status) {
        case 0:
          return "red";
        case 1:
          return "success";
        case 2:
          return "blue";
        default:
          return "grey";
      }
    },
    paymentStatus: {
      get: function () {
        return this.membership.payment?.status;
      },
      set: function (newValue) {
        this.membership.payment.status = newValue;
      },
    },
    paymentTypeId: {
      get: function () {
        return this.membership.payment?.paymentTypeId;
      },
      set: function (newValue) {
        this.membership.payment.paymentTypeId = newValue;
      },
    },
  },
  methods: {
    getValidityUnit() {
      const selectedMembershipType = this.getSelectedMembershipType();

      if (!selectedMembershipType) return null;

      switch (selectedMembershipType.validityUnit) {
        case "day":
          return this.$helpers.string.capitalizeI18N("day");
        case "week":
          return this.$helpers.string.capitalizeI18N("week");
        case "month":
          return this.$helpers.string.capitalizeI18N("month");
        case "year":
          return this.$helpers.string.capitalizeI18N("year");
        default:
          return selectedMembershipType.validityUnit;
      }
    },
    getValidityValue() {
      const selectedMembershipType = this.getSelectedMembershipType();

      if (!selectedMembershipType) return null;

      return selectedMembershipType.validityValue;
    },
    getSelectedMembershipType() {
      return this.$refs.selectableMembershipType?.getMembershipType(
        this.membership.membershipTypeId
      );
    },
    async saveMembership() {
      this.membership.dateBegin = this.dateBegin;
      this.membership.dateEnd = this.dateEnd;
      this.membership.payment.date = this.paymentDate;

      try {
        await this.$services.membership.save(this.membership);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    loadMembership() {
      if (!this.membership.payment) {
        this.membership.payment = { status: 2 };
      }

      this.dateBegin = this.membership.dateBegin
        ? new Date(this.membership.dateBegin).toISOString().substr(0, 10)
        : new Date().toISOString().substr(0, 10);
      this.dateEnd = this.membership.dateEnd
        ? new Date(this.membership.dateEnd).toISOString().substr(0, 10)
        : null;
      this.paymentDate = this.membership.payment?.date
        ? new Date(this.membership.payment.date).toISOString().substr(0, 10)
        : new Date().toISOString().substr(0, 10);
    },
    async retrieveMembershipTypes() {
      await this.$refs.selectableMembershipType?.retrieveMembershipTypes();
    },
    retrieveClients() {
      this.$refs.selectableClient?.retrieveClients();
    },
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        if (!this.$refs.form.validate()) return;

        await this.saveMembership();

        this.$helpers.snackbar.showSuccessI18N("membership.save.success");

        this.$emit("onSaveClicked", this.membership);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    saveAndNew() {
      this.saveMembership();
      this.$emit("onSaveClicked", this.membership);
      this.membership = {};
      this.loadMembership();
      this.$refs.firstName.focus();
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    open(membership) {
      this.membership = membership;
      this.loadMembership();
      this.dialog = true;
    },
    membershipTypeChanged() {
      const selectedMembershipType = this.getSelectedMembershipType();

      if (!selectedMembershipType) return;

      this.computeEndDate();

      this.membership.vatRate = selectedMembershipType.vatRate;
      this.membership.priceVatIncluded =
        selectedMembershipType.priceVatIncluded;

      if (
        this.membership.priceVatIncluded == 0 ||
        this.membership.priceVatIncluded == null
      ) {
        this.paymentStatus = 1;
      }
    },
    computeEndDate(membershipType) {
      const selectedMembershipType =
        membershipType ?? this.getSelectedMembershipType();

      if (!selectedMembershipType) return;

      const validityUnit = selectedMembershipType.validityUnit;
      const validityValue = selectedMembershipType.validityValue;

      if (!validityUnit) {
        this.dateEnd = null;
        return;
      }

      if (!this.dateBegin) return;

      const date = new Date(this.dateBegin);

      if (!date) return;

      var newDate;

      switch (validityUnit) {
        case "day":
          newDate = addDays(date, validityValue);
          break;
        case "week":
          newDate = addWeeks(date, validityValue);
          break;
        case "month":
          newDate = addMonths(date, validityValue);
          break;
        case "year":
          newDate = addYears(date, validityValue);
          break;
      }

      this.dateEnd = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate()
      )
        .toISOString()
        .substr(0, 10);
    },
  },
};
</script>