<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="$attrs['value']"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="$attrs['value']"
        :label="label"
        prepend-icon="access_time"
        readonly
        v-bind="attrs"
        v-on="on"
        type="time"
        :disabled="$attrs['disabled'] != null ? $attrs['disabled'] : false"
        @click:clear="clearClicked()"
        :clearable="$attrs['clearable'] != null ? $attrs['clearable'] : false"
        :error-messages="$attrs['error'] != null ? [$attrs['error']] : null"
        :rules="
          $attrs['required'] != null
            ? $helpers.rule.notEmpty($t('hour-required'))
            : []
        "
      ></v-text-field>
    </template>
    <!-- <v-time-picker
      v-bind="$attrs"
      v-on="$listeners"
      format="24hr"
      @click:minute="$refs.menu.save($attrs['value'])"
      no-title
      scrollable
    >
    </v-time-picker>-->
    <v-time-picker
      v-if="menu"
      format="24hr"
      v-bind="$attrs"
      v-on="$listeners"
      full-width
      @click:minute="$refs.menu.save($attrs['value'])"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  name: "TimePicker",
  props: {
    label: {
      type: String,
      default: "Time",
    },
  },
  directives: {
    mask,
  },
  computed: {
    displayValue() {
      // if (!this.$attrs['value'])
      return null;
      // return this.$attrs['value'].getHours() + ':' + this.$attrs['value'].getMinutes();
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    clearClicked() {
      this.$attrs["value"] = null;
      this.$emit("input", this.$attrs["value"]);

      this.$refs.menu.save(this.$attrs["value"]);
    },
  },
};
</script>