<template>
  <v-select
    :items="paymentTypes"
    :label="$helpers.string.capitalize($tc('payment-type.payment-type', 1), { required: $attrs['required'] != null })"
    v-bind="$attrs"
    v-on="$listeners"
    item-value="id"
    :item-text="getFullName"
    clearable
    prepend-icon="mdi-credit-card"
    :placeholder="$helpers.string.capitalizeI18N('none')"
  ></v-select>
</template>

<script>
export default {
  name: "SelectablePaymentType",
  data() {
    return {
      paymentTypes: [],
    };
  },
  mounted() {
    this.retrievePaymentTypes();
  },
  methods: {
    getFullName(paymentType) {
      return paymentType.name;
    },
    clearClicked() {
      this.$attrs["value"] = null;
      this.$emit("input", this.$attrs["value"]);
      this.$refs.paymentTypesSelect.focus();
    },
    retrievePaymentTypes() {
      return this.$services.paymentType
        .getAll()
        .then((response) => {
          this.paymentTypes = response.data;
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N("payment-types.error.retrieve");
        });
    },
  },
};
</script>