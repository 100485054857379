<template>
  <v-autocomplete
    ref="autocomplete"
    :items="items"
    outlined
    color="blue-grey lighten-2"
    :label="$helpers.string.capitalizeI18N('participants-search')"
    :search-input.sync="search"
    hide-details
    hide-selected
    no-filter
    hide-no-data
    clearable
  >
    <template v-slot:item="{ item }">
      <v-list-item class="row-pointer">
        <v-list-item-content @click="closeAndAddMembership(item, 1)">
          <v-list-item-title>
            <span class="font-weight-medium">{{
              getClientName(item.client)
            }}</span>

            <v-chip
              small
              v-if="item.membership_type"
              class="ml-2 row-pointer"
              :color="item.archived ? `orange` : ``"
              :disabled="item.archived"

              >{{ getMembershipTypeName(item) }}</v-chip
            >
          </v-list-item-title>

          <v-list-item-subtitle>
            <UsedUnitsChip
              :usedUnits="getUsedUnits(item)"
              :totalUnits="Number(item.membership_type.unit)"
              x-small
              class="mr-2 row-pointer"
            />
            <span v-show="item.dateBegin || item.dateEnd"
              >{{ $helpers.date.getDateDisplay(item.dateBegin) }}
              <v-icon small v-if="item.dateEnd">mdi-chevron-right</v-icon>
              {{ $helpers.date.getDateDisplay(item.dateEnd) }}</span
            >
          </v-list-item-subtitle>
          <!-- <v-list-item-subtitle>
            <GroupChips :groups="item.client.groups" x-small />
          </v-list-item-subtitle> -->
        </v-list-item-content>

        <v-list-item-action>
          <v-row>
            <v-btn
              x-small
              color="primary"
              class="ma-2 white--text"
              fab
              @click="addMembership(item, 1)"
              >+1</v-btn
            >
          </v-row>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import UsedUnitsChip from "@/components/Admin/Unit/UsedUnitsChip";
// import GroupChips from "../Group/GroupChips";

export default {
  name: "AvailableMembershipSelection",
  components: {
    UsedUnitsChip,
    // GroupChips,
  },
  props: {
    planning: Object,
  },
  data() {
    return {
      loading: true,
      search: "",
      items: [],
    };
  },
  computed: {},
  watch: {
    search(val) {
      this.customFilter(val);
    },
  },
  methods: {
    getClientName(client) {
      const name = `${client.firstName ?? ""} ${client.lastName ?? ""} ${
        client.companyName ?? ""
      }`.trim();

      return client.active ? name : `${name} (${this.$t("inactive")})`;
    },
    getName(membership) {
      return membership?.client?.firstName + " " + membership?.client?.lastName;
    },
    getMembershipTypeName(membership) {
      return !membership.archived ? membership.membership_type?.name : `${membership.membership_type?.name} (${this.$t('archived')})`;
    },
    getUsedUnits(membership) {
      const tempPlanningMembership =
        this.planning?.planning_memberships
          ?.filter((m) => m.membershipId == membership.id && m.id == null)
          .reduce(function (a, b) {
            return Number(a) + Number(b.unit ?? 0);
          }, 0) ?? 0;

      const planningMembership = membership.planning_memberships
        .filter((m) => m.id != null)
        .reduce(function (a, b) {
          return Number(a) + Number(b.unit ?? 0);
        }, 0);

      return planningMembership + tempPlanningMembership;
    },
    async customFilter(search) {
      if (!search) {
        this.items = [];
        return;
      }

      search = (search ?? "").toLowerCase();

      // server-side search
      try {
        const response = await this.$services.membership.getAllAvailable(
          search,
          this.planning.lessonId
        );

        this.items =
          response.data?.filter(
            (m) => m.unit == null || (m.unit && this.getUsedUnits(m) < m.unit)
          ) ?? [];
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      }
    },
    closeAndAddMembership(membership, unit) {
      this.search = null;
      this.$refs.autocomplete.isMenuActive = false;
      this.$refs.autocomplete.focus();
      this.addMembership(membership, unit);
    },
    addMembership(membership, unit) {
      this.$emit("onAddMembershipClicked", membership, unit);
    },
  },
  mounted() {},
};
</script>

<style>
.row-pointer:hover {
  cursor: pointer;
}
</style>