<template>
  <v-chip
    :color="getPaymentStatusColor()"
    text-color="white"
    label
    x-small
    v-bind="$attrs"
    v-on="$listeners"
    class="text-uppercase"
    >{{ getPaymentStatusLabel() }}</v-chip
  >
</template>

<script>
export default {
  name: "PaymentStatusChip",
  props: {
    payment: Object,
  },
  computed: {},
  methods: {
    getPaymentStatusColor() {
      switch (this.payment?.status) {
        case 0:
          return "red";
        case 1:
          return "success";
        case 2:
          return "blue";
        default:
          return "grey";
      }
    },
    getPaymentStatusLabel() {
      switch (this.payment?.status) {
        case 0:
          return this.$t("payment-status.not-paid");
        case 1:
          return this.$t("payment-status.paid");
        case 2:
          return this.$t("payment-status.pending");
        default:
          return this.$t("payment-status.none");
      }
    },
  },
};
</script>