import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,[_c('SearchBox',{on:{"plusClicked":_vm.newMembership},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"pl-0 pr-0"},[_c(VDataTable,{attrs:{"headers":[

        { text: 'Client', value: 'client.firstName' },
        { text: 'Abonnement', value: 'membership_type.name' },
        { text: 'Validité', value: 'dateEnd' },
        { text: 'Séances', value: 'usedUnits', sortable: false, },
        { text: 'Statut', value: 'payment.status' },
        { text: 'Actions', value: 'actions', sortable: false, } ],"items":_vm.memberships,"search":_vm.searchText,"loading":_vm.loading,"hide-default-footer":true,"disable-pagination":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-filter":_vm.customFilter2},scopedSlots:_vm._u([{key:"item.client.firstName",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getClientName(item))+" ")]}},{key:"item.dateEnd",fn:function(ref){
      var item = ref.item;
return [(item.dateBegin)?_c('span',[_vm._v(_vm._s(_vm.$helpers.date.getDateDisplay(item.dateBegin)))]):_vm._e(),(item.dateEnd)?_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]):_vm._e(),(item.dateEnd)?_c('span',[_vm._v(_vm._s(_vm.$helpers.date.getDateDisplay(item.dateEnd)))]):_vm._e(),_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(_vm.showOutOfDate(item)),expression:"showOutOfDate(item)"}],staticClass:"text-uppercase ml-2",attrs:{"color":"error","text-color":"white","label":"","x-small":""}},[_vm._v(_vm._s(_vm.$t('expired')))])]}},{key:"item.usedUnits",fn:function(ref){
      var item = ref.item;
return [_c('MembershipUnitsDetails',{attrs:{"membership":item},on:{"edit":_vm.editPlanning}})]}},{key:"item.payment.status",fn:function(ref){
      var item = ref.item;
return [(item.archived)?_c(VChip,{staticClass:"text-uppercase mr-2",attrs:{"x-small":"","label":"","outlined":"","color":"blue-grey"}},[_vm._v("archivé")]):_vm._e(),_c('PaymentStatusChip',{attrs:{"payment":item.payment}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editMembership(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-pencil-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier l'abonnement")])]),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{staticClass:"deep-orange--text",on:{"click":function($event){return _vm.removeMembership(item)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"deep-orange--text"},[_vm._v("mdi-trash-can-outline")]),_vm._v(" Supprimer")],1)],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('NoTableData',{on:{"click":_vm.newMembership}})]},proxy:true},{key:"loading",fn:function(){return [_c('Loader')]},proxy:true}])})],1)],1),_c('MembershipEditionPopup',{ref:"membershipEdition",on:{"onSaveClicked":_vm.saveClicked,"onCloseClicked":_vm.retrieveAll}}),_c('PlanningEditionPopup',{ref:"planningEdition",on:{"onSaveClicked":_vm.saveClicked,"onCloseClicked":_vm.retrieveAll}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }