<template>
  <v-container>
    <v-row>
      <v-col>
        <!-- <MembershipSearchBox v-model="search" @plusClicked="newMembership" /> -->
        <SearchBox v-model="searchText" @plusClicked="newMembership" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pl-0 pr-0">

        <v-data-table :headers="[

          { text: 'Client', value: 'client.firstName' },
          { text: 'Abonnement', value: 'membership_type.name' },
          { text: 'Validité', value: 'dateEnd' },
          { text: 'Séances', value: 'usedUnits', sortable: false, },
          { text: 'Statut', value: 'payment.status' },
          { text: 'Actions', value: 'actions', sortable: false, },
        ]" :items="memberships" :search="searchText" :loading="loading" :hide-default-footer="true" disable-pagination
          :sort-by="sortBy" :sort-desc="sortDesc" :custom-filter="customFilter2">
          <template v-slot:item.client.firstName="{ item }">
            {{ getClientName(item) }}
          </template>

          <template v-slot:item.dateEnd="{ item }">
            <span v-if="item.dateBegin">{{ $helpers.date.getDateDisplay(item.dateBegin) }}</span>
            <v-icon v-if="item.dateEnd" small>mdi-chevron-right</v-icon>
            <span v-if="item.dateEnd">{{ $helpers.date.getDateDisplay(item.dateEnd) }}</span>

            <v-chip v-show="showOutOfDate(item)" color="error" text-color="white" label x-small
              class="text-uppercase ml-2">{{ $t('expired') }}</v-chip>
          </template>

          <template v-slot:item.usedUnits="{ item }">
            <MembershipUnitsDetails :membership="item" @edit="editPlanning" />
          </template>

          <template v-slot:item.payment.status="{ item }">
            <v-chip x-small label v-if="item.archived" outlined color="blue-grey"
              class="text-uppercase mr-2">archivé</v-chip>
            <PaymentStatusChip :payment="item.payment" />
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="editMembership(item)" v-bind="attrs" v-on="on" icon>
                  <v-icon>
                    mdi-pencil-box
                  </v-icon>
                </v-btn>
              </template>
              <span>Modifier l'abonnement</span>
            </v-tooltip>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="removeMembership(item)" class="deep-orange--text">
                  <v-list-item-title><v-icon class="deep-orange--text">mdi-trash-can-outline</v-icon>
                    Supprimer</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

          </template>
          <template v-slot:no-data>
            <NoTableData @click="newMembership" />
          </template>
          <template v-slot:loading>
            <Loader />
          </template>
        </v-data-table>

      </v-col>
    </v-row>

    <MembershipEditionPopup ref="membershipEdition" @onSaveClicked="saveClicked" @onCloseClicked="retrieveAll" />

    <PlanningEditionPopup ref="planningEdition" @onSaveClicked="saveClicked" @onCloseClicked="retrieveAll" />
  </v-container>
</template>

<script>
// import MembershipCard from "@/components/Admin/Membership/MembershipCard";
import MembershipEditionPopup from "@/components/Admin/Membership/MembershipEditionPopup";
// import MembershipSearchBox from "@/components/Admin/Membership/MembershipSearchBox";
import PlanningEditionPopup from "@/components/Admin/Planning/PlanningEditionPopup";
import Loader from "@/components/Admin/Loader";
import NoTableData from "@/components/Admin/NoTableData.vue";
import PaymentStatusChip from "@/components/Admin/Payment/PaymentStatusChip";
import MembershipUnitsDetails from "../../components/Admin/Membership/MembershipUnitsDetails.vue";
import SearchBox from "@/components/Admin/SearchBox";

export default {
  components: {
    // MembershipCard,
    MembershipEditionPopup,
    // MembershipSearchBox,
    PlanningEditionPopup,
    Loader,
    NoTableData,
    PaymentStatusChip,
    MembershipUnitsDetails,
    SearchBox,
  },
  data() {
    return {
      loading: false,
      itemsPerPageArray: [4, 8, 12],
      search: {
        text: "",
        expired: null,
      },
      searchText: "",
      filter: {},
      sortDesc: true,
      page: 1,
      itemsPerPage: 20,
      sortBy: "dateEnd",
      memberships: [],
      filteredMemberships: [],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.memberships.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== `Name`);
    },
  },
  watch: {
    search: {
      handler: function () {
        this.customFilter();
      },
      deep: true,
    },
  },
  methods: {
    getClientName(membership) {
      return this.$helpers.string.getClientFullName(membership.client);
    },
    showOutOfDate(membership) {
      return new Date() > (this.$helpers.date.getDate(membership.dateEnd) ?? new Date());
    },
    customFilter2(items, search, filter) {
      search = search.toString().toLowerCase();

      return filter.client.firstName?.toLowerCase().includes(search) ||
        filter.client.lastName?.toLowerCase().includes(search) ||
        filter.client.companyName?.toLowerCase().includes(search) ||
        filter.client.comment?.toLowerCase().includes(search) ||
        filter.client.groups.find((g) =>
          g.name?.toLowerCase().includes(search)
        );
    },
    customFilter() {
      console.dir("search", this.search);
      if (!this.search.text && this.search.expired == null) {
        this.filteredMemberships = this.memberships;
        return;
      }

      let search = (this.search.text ?? "").toLowerCase();

      let filteredItems = this.memberships.filter(
        (element) =>
          element.client.firstName?.toLowerCase().includes(search) ||
          element.client.lastName?.toLowerCase().includes(search) ||
          element.client.companyName?.toLowerCase().includes(search) ||
          element.client.comment?.toLowerCase().includes(search) ||
          element.client.groups.find((g) =>
            g.name?.toLowerCase().includes(search)
          )
      );

      if (this.search.expired != null) {
        filteredItems = filteredItems.filter((element) => {
          if (!element.dateEnd) return !this.search.expired;

          const dateEnd = this.$helpers.date.getDate(element.dateEnd);

          if (this.search.expired) {
            if (new Date() > dateEnd) return true;

            if (element.membership_type.unit == null) return false;

            return (
              this.getUsedUnits(element) >= Number(element.membership_type.unit)
            );
          }

          return (
            new Date() < dateEnd &&
            (!element.membership_type.unit ||
              this.getUsedUnits(element) < Number(element.membership_type.unit))
          );
        });
      }

      this.filteredMemberships = filteredItems;
    },
    getUsedUnits(membership) {
      return membership.planning_memberships.reduce(function (a, b) {
        return Number(a) + Number(b.unit ?? 0);
      }, 0);
    },
    newMembership() {
      this.$refs.membershipEdition.open({
        active: true,
        vatRate: 21,
        payment: { status: 2 },
      });
    },
    editMembership(membership) {
      var value = JSON.parse(JSON.stringify(membership));
      this.$refs.membershipEdition.open(value);
    },
    async removeMembership(membership) {
      const usedUnits = this.getUsedUnits(membership);
      const totalUnits = Number(membership.membership_type.unit);

      let message = "Voulez-vous réellement supprimer cet abonnement ou cette séance ?";

      if (usedUnits < totalUnits) {
        message = message.concat(`<br /><br />Attention, les ${totalUnits - usedUnits} unités restantes ${usedUnits > 0 ? `et les ${usedUnits} unités déjà utilisées ` : ''}seront supprimées.`);
      }

      var confirm = await this.$root.$confirm(
        this.$helpers.string.capitalizeI18N("confirmation"),
        message,
      );

      if (confirm) {
        try {
          await this.deleteMembership(membership);

          this.$helpers.snackbar.showSuccessI18N(
            "membership.delete.success"
          );

          this.saveClicked();
        } catch (error) {
          this.$helpers.snackbar.handleError(error);
        }
      }

    },
    async deleteMembership(membership) {
      try {
        await this.$services.membership.delete(membership);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    editPlanning(planning) {
      this.$services.planning
        .get(planning.id)
        .then((response) => {
          this.$refs.planningEdition.open(response.data);
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    saveClicked() {
      this.retrieveAll();
    },
    retrieveMemberships() {
      this.loading = true;
      this.$services.membership
        .getAll()
        .then((response) => {
          this.memberships = response.data;
          this.filteredMemberships = this.memberships;
          this.loading = false;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    retrieveAll() {
      this.retrieveMemberships();
    },
  },
  mounted() {
    this.retrieveAll();
  },
};
</script>