import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{ref:"dialog",attrs:{"return-value":_vm.planning,"persistent":"","max-width":"800px","fullscreen":_vm.$helpers.display.mobileDialog,"hide-overlay":_vm.$helpers.display.mobileDialog,"transition":"dialog-bottom-transition"},on:{"update:returnValue":function($event){_vm.planning=$event},"update:return-value":function($event){_vm.planning=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[(_vm.planning.canceled)?_c(VIcon,{staticClass:"mr-2",attrs:{"x-large":""}},[_vm._v("mdi-cancel")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$helpers.string.capitalizeI18N( _vm.planning.id == null ? "planning.creation" : _vm.planning.canceled ? "planning.canceled" : "planning.edition" ))+" ")],1),_c(VSpacer),_c('PopupCloseButton',{on:{"closeClick":_vm.close}})],1),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"readonly":_vm.planning.canceled}},[_c('FormGroupElement',{attrs:{"showMoreButton":false,"expanded":true}},[_c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[(!_vm.planning.name)?_c('SelectableLesson',{ref:"selectableLesson",attrs:{"required":"","disabled":_vm.planning.canceled},on:{"input":_vm.setLesson},model:{value:(_vm.planning.lessonId),callback:function ($$v) {_vm.$set(_vm.planning, "lessonId", $$v)},expression:"planning.lessonId"}}):_vm._e()],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('DatePicker',{attrs:{"label":_vm.$helpers.string.capitalizeI18N('date-begin', {
                    required: true,
                  }),"required":"","disabled":_vm.planning.canceled},model:{value:(_vm.dateBegin),callback:function ($$v) {_vm.dateBegin=$$v},expression:"dateBegin"}}),_c('DatePicker',{attrs:{"label":_vm.$helpers.string.capitalizeI18N('date-end', {
                    required: true,
                  }),"required":"","disabled":_vm.planning.canceled},model:{value:(_vm.dateEnd),callback:function ($$v) {_vm.dateEnd=$$v},expression:"dateEnd"}})],1),_c(VCol,{staticClass:"ml-2"},[_c('TimePicker',{attrs:{"label":_vm.$helpers.string.capitalizeI18N('hour-begin', {
                    required: true,
                  }),"required":"","disabled":_vm.planning.canceled},model:{value:(_vm.timeBegin),callback:function ($$v) {_vm.timeBegin=$$v},expression:"timeBegin"}}),_c('TimePicker',{attrs:{"label":_vm.$helpers.string.capitalizeI18N('hour-end', {
                    required: true,
                  }),"required":"","disabled":_vm.planning.canceled},model:{value:(_vm.timeEnd),callback:function ($$v) {_vm.timeEnd=$$v},expression:"timeEnd"}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('SelectableUser',{ref:"selectableUser",attrs:{"disabled":_vm.planning.canceled},model:{value:(_vm.planning.userId),callback:function ($$v) {_vm.$set(_vm.planning, "userId", $$v)},expression:"planning.userId"}})],1)],1)],1)],1),_c('FormGroupElement',{directives:[{name:"show",rawName:"v-show",value:(_vm.canEditMembership()),expression:"canEditMembership()"}],attrs:{"expanded":_vm.showMemberships},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$helpers.string.capitalizeI18N("participants"))+" ")]),_c('PlanningUnitsChip',{attrs:{"usedUnits":_vm.getUsedUnits(),"totalUnits":_vm.getMaximumUnit(),"small":""}})]},proxy:true}])},[_c('MembershipSelection',{ref:"membershipSelection",attrs:{"lesson":_vm.lesson},model:{value:(_vm.planning),callback:function ($$v) {_vm.planning=$$v},expression:"planning"}})],1)],1),_c(VContainer,[_c(VRow,[_c(VCol,[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.planning.id),expression:"planning.id"}],attrs:{"block":"","text":"","color":"error","loading":_vm.deleteLoading}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" supprimer "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.remove({ next: false })}}},[_c(VListItemTitle,[_vm._v("Cette séance uniquement")])],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.remove({ next: true })}}},[_c(VListItemTitle,[_vm._v("Cette séance et les suivantes")])],1)],1)],1)],1),_c(VCol,[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.planning.id),expression:"planning.id"}],attrs:{"block":"","text":"","color":"info","disabled":_vm.planning.canceled,"loading":_vm.cancelLoading}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" annuler "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.cancel({ next: false })}}},[_c(VListItemTitle,[_vm._v("Cette séance uniquement")])],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.cancel({ next: true })}}},[_c(VListItemTitle,[_vm._v("Cette séance et les suivantes")])],1)],1)],1)],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c('CardActionButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.planning.canceled),expression:"!planning.canceled"}],ref:"saveButton",attrs:{"type":"save"},on:{"click":_vm.saveAndClose}})],1)],1)],1),_c('CheckboxConfirmDialog',{ref:"checkboxConfirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }