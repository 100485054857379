<template>
  <v-toolbar :collapse="!searchMode" dense>
    <slot name="plus"></slot>
    <v-btn v-if="!$slots.plus" fab x-small color="primary" @click="plusClicked" v-show="$listeners.plusClicked">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn class="ml-3" icon @click="toggleSearchMode">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <!-- @blur="searchMode = false" -->
    <v-text-field ref="searchTextField" v-if="searchMode" hide-details single-line v-model="$attrs['value']"
      @input="setSearch($event)" clearable></v-text-field>

    <!-- <v-btn icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn> -->
  </v-toolbar>
</template>

<script>
export default {
  name: "SearchBox",
  data() {
    return {
      searchMode: true,
    };
  },
  methods: {
    toggleSearchMode() {
      // this.searchMode = !this.searchMode;

      if (this.searchMode) {
        this.$nextTick(() => this.$refs.searchTextField.focus());
      }
    },
    setSearch(event) {
      this.$attrs["value"] = event;
      this.$emit("input", this.$attrs["value"]);
    },
    plusClicked() {
      this.$emit("plusClicked");
    },
  },
};
</script>