import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.$attrs['value'],"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.$attrs, 'value', $event)},"update:return-value":function($event){return _vm.$set(_vm.$attrs, 'value', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"prepend-icon":"access_time","readonly":"","type":"time","disabled":_vm.$attrs['disabled'] != null ? _vm.$attrs['disabled'] : false,"clearable":_vm.$attrs['clearable'] != null ? _vm.$attrs['clearable'] : false,"error-messages":_vm.$attrs['error'] != null ? [_vm.$attrs['error']] : null,"rules":_vm.$attrs['required'] != null
          ? _vm.$helpers.rule.notEmpty(_vm.$t('hour-required'))
          : []},on:{"click:clear":function($event){return _vm.clearClicked()}},model:{value:(_vm.$attrs['value']),callback:function ($$v) {_vm.$set(_vm.$attrs, 'value', $$v)},expression:"$attrs['value']"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.menu)?_c(VTimePicker,_vm._g(_vm._b({attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.$attrs['value'])}}},'v-time-picker',_vm.$attrs,false),_vm.$listeners)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }