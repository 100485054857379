<template>
  <v-select :items="membershipTypes" :label="label ? label : $helpers.string.capitalize($tc('membership-type.membership-type', 1), {
    required: $attrs['required'] != null,
  })
    " v-bind="$attrs" v-on="$listeners" item-value="id" :item-text="getFullName" clearable
    prepend-icon="mdi-tag-text-outline" :rules="$attrs['required'] != null ? $helpers.rule.notEmpty() : []">
    <template v-slot:selection="data">
      <v-icon small>{{ getIconName(data.item) }}</v-icon>

      <v-tooltip bottom :color="data.item.publicAvailable ? 'green' : 'deep-orange'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" :color="data.item.publicAvailable ? 'green' : 'deep-orange'">
            mdi-circle-small
          </v-icon>
        </template>
        <span>{{ data.item.publicAvailable ? $t('membership-type.public-available-yes') :
          $t('membership-type.public-available-no') }}</span>
      </v-tooltip>

      {{ data.item.name }}

      <v-chip v-if="data.item.active == 0" color="red" small text-color="white" class="ml-2">{{
        $t("inactive")
      }}</v-chip>
    </template>
    <template v-slot:item="data">
      <v-icon small>{{ getIconName(data.item) }}</v-icon>

      <v-tooltip bottom :color="data.item.publicAvailable ? 'green' : 'deep-orange'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" :color="data.item.publicAvailable ? 'green' : 'deep-orange'">
            mdi-circle-small
          </v-icon>
        </template>
        <span>{{ data.item.publicAvailable ? $t('membership-type.public-available-yes') :
          $t('membership-type.public-available-no') }}</span>
      </v-tooltip>

      {{ data.item.name }}

      <v-chip v-if="data.item.active == 0" color="red" small text-color="white" class="ml-2">{{
        $t("inactive")
      }}</v-chip>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "SelectableMembershipType",
  props: {
    label: String,
  },
  data() {
    return {
      membershipTypes: [],
    };
  },
  components: {},
  mounted() {
    this.retrieveMembershipTypes();
  },
  methods: {
    getIconName(membershipType) {
      switch (membershipType.unit) {
        case "1":
          return "mdi-numeric-1-box";
        case null:
          return "mdi-infinity";
        default:
          return "mdi-numeric";
      }
    },

    getFullName(membershipType) {
      return membershipType.active
        ? membershipType.name
        : `${membershipType.name} (${this.$t("inactive")})`;
    },
    retrieveMembershipTypes() {
      return this.$services.membershipType
        .getAll()
        .then((response) => {
          this.membershipTypes = response.data;
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N(
            "membershipTypes.error.retrieve"
          );
        });
    },
    getMembershipType(membershipTypeId) {
      return this.membershipTypes.find((m) => m.id === membershipTypeId);
    },
  },
};
</script>