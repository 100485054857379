<template>
  <v-container>
    <v-row>
      <AvailableMembershipSelection :planning="$attrs.value" @onAddMembershipClicked="addMembership"
        v-show="canEditMembership" />
    </v-row>
    <v-row>
      <v-col>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Client
                </th>
                <th class="text-left">
                  Abonnement
                </th>
                <th class="text-left" style="width: 60px !important;">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon left small>
                          mdi-information
                        </v-icon>
                        Nb. unité
                      </span>
                    </template>
                    <span>Ajustez le nombre d'unité pour décrémenter ou non l'abonnement</span>
                  </v-tooltip>
                </th>
                <th class="text-left">

                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in planningMemberships" :key="item.id">
                <td>
                  {{ getFullClientName(item.membership.client) }}
                </td>
                <td>
                  {{ getMembershipTypeName(item.membership.membershipTypeId) }}
                  <!-- <pre>{{ item }}</pre> -->
                </td>
                <td class="d-flex " style="background-color: transparent;">
                  <v-select class="d-flex align-center align-self-start mt-1" :items="[0, 1]" v-model.number="item.unit"
                    dense outlined :disabled="!canEditMembership"></v-select>
                  <!-- <v-combobox v-model="item.unit" :items="[0, 1,]" solo dense small-chips :disabled="!canEditMembership"
                    class="shrink"></v-combobox> -->
                </td>
                <td>
                  <v-btn x-small color="primary" fab @click="removePlanningMembership(item)" class="ml-2"
                    :disabled="!canEditMembership">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- <v-container v-show="showCurrentMembership">
          <v-row
            align="center"
            justify="start"
            v-for="item in $attrs['value'].planning_memberships"
            :key="item.id"
            class="mb-4"
            no-gutters
          >
            <v-col class="grow">
              {{ getFullClientName(item.membership.client) }}</v-col
            >
            <v-col class="shrink">
              <span style="width: 100px;">
                <v-combobox
                  v-model="item.unit"
                  :items="[0, 1, 2, 3, 4, 5]"
                  solo
                  dense
                  small-chips
                  :disabled="!canEditMembership"
                ></v-combobox>
              </span>
              <v-btn
                x-small
                color="primary"
                fab
                @click="removePlanningMembership(item)"
                class="ml-2"
                :disabled="!canEditMembership"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container> -->

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AvailableMembershipSelection from "./AvailableMembershipSelection";

export default {
  name: "MembershipSelection",
  components: {
    AvailableMembershipSelection,
  },
  props: {
    lesson: Object,
  },
  data() {
    return {
      loading: true,
      maximumUnit: null,
      membershipTypes: [],
    };
  },
  computed: {
    planningMemberships() {
      this.$attrs["value"].planning_memberships?.forEach(pm => pm.unit = Number(pm.unit));

      return this.$attrs["value"].planning_memberships ?? [];
    },
    showCurrentMembership() {
      //return true;
      if (this.$attrs["value"].planning_memberships == null) return false;

      if (this.$attrs["value"].planning_memberships.length == 0) return false;

      return true;
    },
    canEditMembership() {
      if (!this.planning) return true;

      if (!this.planning.lessonId) return false;

      return !this.planning.canceled;
    },
    planning() {
      return this.$attrs["value"];
    },
  },
  methods: {
    getMembershipTypeName(membershipTypeId) {
      return this.membershipTypes.find(mt => mt.id == membershipTypeId)?.name ?? '';
    },
    getFullClientName(client) {
      const fullName = (
        (client.firstName ?? "") +
        " " +
        (client.lastName ?? "")
      ).trim();

      return client.companyName
        ? fullName
          ? `${fullName} (${client.companyName})`
          : client.companyName
        : fullName;
    },
    addMembership(membership, unit) {
      const newPlanningMembership = {
        membershipId: membership.id,
        unit: unit,
        membership: JSON.parse(JSON.stringify(membership)),
      };

      this.$attrs["value"].planning_memberships.push(newPlanningMembership);
    },
    removePlanningMembership(planningMembership) {
      const indexPlanning =
        this.$attrs["value"].planning_memberships.indexOf(planningMembership);
      if (indexPlanning >= 0)
        this.$attrs["value"].planning_memberships.splice(indexPlanning, 1);
    },
    retrieveMembershipTypes() {
      return this.$services.membershipType
        .getAll()
        .then((response) => {
          this.membershipTypes = response.data;
          console.log('this.membershipTypes', this.membershipTypes)
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N(
            "membershipTypes.error.retrieve"
          );
        });
    },
  },
  async mounted() {
    console.log('onmounted', this.$attrs['value'])
    await this.retrieveMembershipTypes();
  },
};
</script>