<template>
  <v-select
    :items="users"
    :label="
      $helpers.string.capitalize($tc('user.user', 1), {
        required: $attrs['required'] != null,
      })
    "
    v-bind="$attrs"
    v-on="$listeners"
    item-value="id"
    :item-text="getFullName"
    clearable
    :disabled="$attrs['disabled'] != null ? $attrs['disabled'] : false"
    prepend-icon="mdi-account-box-outline"
  ></v-select>
</template>

<script>
export default {
  name: "SelectableUser",
  data() {
    return {
      users: [],
    };
  },
  components: {},
  mounted() {
    this.retrieveUsers();
  },
  methods: {
    getFullName(user) {
      return user.firstName + " " + user.lastName;
    },
    retrieveUsers() {
      return this.$services.user
        .getAll()
        .then((response) => {
          this.users = response.data;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
  },
};
</script>