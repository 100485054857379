import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{ref:"autocomplete",attrs:{"items":_vm.items,"outlined":"","color":"blue-grey lighten-2","label":_vm.$helpers.string.capitalizeI18N('participants-search'),"search-input":_vm.search,"hide-details":"","hide-selected":"","no-filter":"","hide-no-data":"","clearable":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItem,{staticClass:"row-pointer"},[_c(VListItemContent,{on:{"click":function($event){return _vm.closeAndAddMembership(item, 1)}}},[_c(VListItemTitle,[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.getClientName(item.client)))]),(item.membership_type)?_c(VChip,{staticClass:"ml-2 row-pointer",attrs:{"small":"","color":item.archived ? "orange" : "","disabled":item.archived}},[_vm._v(_vm._s(_vm.getMembershipTypeName(item)))]):_vm._e()],1),_c(VListItemSubtitle,[_c('UsedUnitsChip',{staticClass:"mr-2 row-pointer",attrs:{"usedUnits":_vm.getUsedUnits(item),"totalUnits":Number(item.membership_type.unit),"x-small":""}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.dateBegin || item.dateEnd),expression:"item.dateBegin || item.dateEnd"}]},[_vm._v(_vm._s(_vm.$helpers.date.getDateDisplay(item.dateBegin))+" "),(item.dateEnd)?_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$helpers.date.getDateDisplay(item.dateEnd)))],1)],1)],1),_c(VListItemAction,[_c(VRow,[_c(VBtn,{staticClass:"ma-2 white--text",attrs:{"x-small":"","color":"primary","fab":""},on:{"click":function($event){return _vm.addMembership(item, 1)}}},[_vm._v("+1")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }